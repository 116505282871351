.cardDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  
}
.cardChild {
  width: 100%;
  opacity: 0.9;
  margin-top: 15px;
  padding: 10px;
}

@media only screen and (max-width: 767px) {
  .cardDiv{
      margin-left: 3%;
      text-align: center;
      
  } 
}
@media only screen and (max-width: 576px) {
  .cardDiv{
      margin-left: 0;
      text-align: center; 
  } 
}