.carousel {
  margin-top: 10px;
  opacity: 0.9;
  background-color: rgb(0, 0, 0);
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.carasolevent {
  background-color: tomato;
  display: inline;
  border-radius: 5%;
}

.attributename {
  font-size: 7px;
}
@media only screen and (max-width: 767px) {
  .carousel {
    margin-top: 0;
    background-color: transparent;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .carousel {
    margin-top: 0;
    background-color: transparent;
    margin-left: 0;
  }
}
