.members_outer{
    margin-top: 10px;
    opacity: 0.9;
    background-color: rgb(221, 221, 238);
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding-left: 10px;
}
@media only screen and (max-width: 767px) {
    .members_outer{
        margin-left: 20px;
        padding-top: 10px;
    } 
  }
  @media only screen and (max-width: 576px) {
    .members_outer{
        margin: 0;
    } 
  }