.popup-box {
  position: fixed;
  background: #25131350;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.popup-inner {
  position: relative;
  margin-top: 2%;
  height: auto;
  background: rgb(226, 219, 219);
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}
.formCancelBtn {
  width: 80px;
  margin-top: 3px;
  margin-left: 40%;
}
.submitBtn {
  margin-top: 3px;
}
.canclePopup {
  margin-left: 10px;
  width: 45px;
}
.thankYouPopup {
  display: flex;
  justify-content: center;
  width: 20%;
}

.form_outer {
  margin-top: 10px;
  opacity: 0.9;
  background-color: rgb(221, 221, 238);
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
@media only screen and (max-width: 767px) {
  .form_outer {
    margin-left: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
}
.formDiv {
  height: 100%;
  text-align: center;
}
@media only screen and (max-width: 576px) {
  .form_outer {
    margin-top: 0;
    margin-left: 0;
  }
}
